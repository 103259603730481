const allAmenities = [
  { pordalCode: "elevator",                     icon: "elevator",           text: "Elevator" },
  { pordalCode: "parking",                      icon: "parking",            text: "Parking" },
  { pordalCode: null,                           icon: "parking",            text: "Free Parking on Premises" },
  { pordalCode: null,                           icon: "parking",            text: "Paid Parking off Premises" },
  { pordalCode: null,                           icon: "parking",            text: "Free Parking on Street" },
  { pordalCode: "wheelchair-accessible",        icon: "wheelchair",         text: "Wheelchair Accessible" },
  { pordalCode: "gym",                          icon: "gym",                text: "Gym" },
  { pordalCode: null,                           icon: "pool",               text: "Swimming Pool" },
  { pordalCode: "private-pool",                 icon: "pool",               text: "Private Pool" },
  { pordalCode: "indoor-pool",                  icon: "pool",               text: "Indoor Pool" },
  { pordalCode: "outdoor-pool",                 icon: "pool",               text: "Outdoor Pool" },
  { pordalCode: "hot-tub",                      icon: "hot_tub",            text: "Hot Tub" },
  { pordalCode: null,                           icon: "air_condition",      text: "Air Conditioning" },
  { pordalCode: "central-air-conditioning",     icon: "air_condition",      text: "Central air conditioning" },
  { pordalCode: "portable-air-conditioning",    icon: "air_condition",      text: "Portable air conditioning" },
  { pordalCode: "heating",                      icon: "heating",            text: "Heating" },
  { pordalCode: "fireplace",                    icon: "fireplace",          text: "Indoor Fireplace" },
  { pordalCode: "wi-fi",                        icon: "wifi",               text: "Wireless Internet" },
  { pordalCode: "internet-reach-in-all-rooms",  icon: "wifi",               text: "Internet reach in all rooms" },
  { pordalCode: null,                           icon: "internet",           text: "Internet" },
  { pordalCode: null,                           icon: "laptop",             text: "Laptop Friendly Workspace" },
  { pordalCode: "desk-workspace",               icon: "laptop",             text: "Desk / workspace" },
  { pordalCode: "kitchen",                      icon: "kitchen",            text: "Kitchen" },
  { pordalCode: null,                           icon: "washer",             text: "Washer" },
  { pordalCode: "washing-machine-paid",         icon: "washer",             text: "Paid washing machine" },
  { pordalCode: "washing-machine-in-building",  icon: "washer",             text: "Washing machine in building" },
  { pordalCode: null,                           icon: "dryer",              text: "Dryer" },
  { pordalCode: "dryer-paid",                   icon: "dryer",              text: "Paid dryer" },
  { pordalCode: "dryer-in-building",            icon: "dryer",              text: "Dryer in building" },
  { pordalCode: "hair-dryer",                   icon: "hair_dryer",         text: "Hair Dryer" },
  { pordalCode: "iron",                         icon: "iron",               text: "Iron" },
  { pordalCode: "tv-standard-cable",            icon: "tv",                 text: "Cable TV" },
  { pordalCode: "tv-hd",                        icon: "tv",                 text: "TV" },
  { pordalCode: "tv-size",                      icon: "tv",                 text: "TV Size" },
  { pordalCode: "tv-amazon",                    icon: "tv_amazon",          text: "Amazon" },
  { pordalCode: "tv-netflix",                   icon: "tv_netflix",         text: "Netflix" },
  { pordalCode: null,                           icon: "keypad",             text: "Keypad" },
  { pordalCode: "essentials",                   icon: "essentials",         text: "Essentials" },
  { pordalCode: "first-aid-kit",                icon: "first_aid",          text: "First Aid Kit" },
  { pordalCode: "shampoo",                      icon: "shampoo",            text: "Shampoo" },
  { pordalCode: "hangers",                      icon: "hangers",            text: "Hangers" },
  { pordalCode: "smoke-detector",               icon: "smoke_detector",     text: "Smoke Detector" },
  { pordalCode: "carbon-monoxide-detector",     icon: "CM_detector",        text: "Carbon Monoxide Detector" },
  { pordalCode: "fire-extinguisher",            icon: "fire_extinguisher",  text: "Fire Extinguisher" },
  { pordalCode: null,                           icon: "safety_card",        text: "Safety Card" },
  { pordalCode: null,                           icon: "buzzer",             text: "Buzzer/Wireless Intercom" },
  { pordalCode: "private-entrance",             icon: "private_entrance",   text: "Private entrance" },
  { pordalCode: null,                           icon: "private_livingroom", text: "Private livingroom" },
  { pordalCode: "lock-bedroom-door",            icon: "locker",             text: "Lock on Bedroom Door" },
  { pordalCode: "doorman",                      icon: "doorman",            text: "Doorman" },
  { pordalCode: "lock-box",                     icon: "lockbox",            text: "Lockbox" },
  { pordalCode: "smart-lock",                   icon: "smartlock",          text: "Smartlock" },
  { pordalCode: "suitable-for-children",        icon: "family_friendly",    text: "Family/Kid Friendly" },
  { pordalCode: "allow-smoking",                icon: "smoking",            text: "Smoking Allowed" },
  { pordalCode: "allow-events",                 icon: "events",             text: "Suitable for Events" },
  { pordalCode: null,                           icon: "checkin",            text: "24-Hour Check-in" },
  { pordalCode: null,                           icon: "checkin",            text: "Self Check-in" },
  { pordalCode: "breakfast-coffee-tea",         icon: "breakfast",          text: "Breakfast" },
  { pordalCode: "allow-pets",                   icon: "pets_allowed",       text: "Pets Allowed" },
  { pordalCode: null,                           icon: "pets_live",          text: "Pets live on this property" },
  { pordalCode: null,                           icon: "baby",               text: "Baby Monitor" },
  { pordalCode: null,                           icon: "baby",               text: "Baby bath" },
  { pordalCode: null,                           icon: "baby",               text: "Babysitter recommendations" },
  { pordalCode: null,                           icon: "baby",               text: "Pack ’n Play/travel crib" },
  { pordalCode: null,                           icon: "family_friendly",    text: "Children’s books and toys" },
  { pordalCode: null,                           icon: "family_friendly",    text: "Children’s dinnerware" },
  { pordalCode: "bathtub",                      icon: "bath",               text: "Bathtub" },
  { pordalCode: null,                           icon: "bath",               text: "Tub with shower bench" },
  { pordalCode: null,                           icon: "wheelchair",         text: "Accessible-height bed" },
  { pordalCode: null,                           icon: "wheelchair",         text: "Accessible-height toilet" },
  { pordalCode: "all-bed-linens",               icon: "bed",                text: "Bed linens" },
  { pordalCode: null,                           icon: "bed",                text: "Wide clearance to bed" },
  { pordalCode: null,                           icon: "guard",              text: "Stair gates" },
  { pordalCode: null,                           icon: "guard",              text: "Window guards" },
  { pordalCode: null,                           icon: "guard",              text: "Table corner guards" },
  { pordalCode: null,                           icon: "guard",              text: "Fireplace guards" },
  { pordalCode: null,                           icon: "guard",              text: "Outlet covers" },
  { pordalCode: null,                           icon: "parking",            text: "Disabled parking spot" },
  { pordalCode: null,                           icon: "accessibility",      text: "Wide hallway clearance" },
  { pordalCode: null,                           icon: "accessibility",      text: "Step-free access" },
  { pordalCode: null,                           icon: "accessibility",      text: "Wide doorway" },
  { pordalCode: null,                           icon: "accessibility",      text: "Flat smooth pathway to front door" },
  { pordalCode: null,                           icon: "accessibility",      text: "Path to entrance lit at night" },
  { pordalCode: "",                             icon: "accessibility",      text: "Grab-rails for shower and toilet" },
  { pordalCode: "shower-rail",                  icon: "accessibility",      text: "Shower rail" },
  { pordalCode: "shower-rings",                 icon: "accessibility",      text: "Shower rings" },
  { pordalCode: null,                           icon: "accessibility",      text: "Roll-in shower with shower bench or chair" },
  { pordalCode: null,                           icon: "accessibility",      text: "Wide clearance to shower and toilet" },
  { pordalCode: null,                           icon: "table",              text: "Changing table" },
  { pordalCode: "high-chair",                   icon: "chair",              text: "High chair" },
  { pordalCode: null,                           icon: "shades",             text: "Room darkening shades" },
  { pordalCode: null,                           icon: "console",            text: "Game console" },
  { pordalCode: "microwave",                    icon: "microwave",          text: "Microwave" },
  { pordalCode: "coffee-machine",               icon: "coffee",             text: "Coffee maker" },
  { pordalCode: "refrigerator",                 icon: "refrigerator",       text: "Refrigerator" },
  { pordalCode: "dishwasher",                   icon: "dishwasher",         text: "Dishwasher" },
  { pordalCode: "plate-set",                    icon: "dishes",             text: "Plate set" },
  { pordalCode: null,                           icon: "dishes",             text: "Dishes and silverware" },
  { pordalCode: "",                             icon: "kitchen",            text: "Cookware" },
  { pordalCode: "oven",                         icon: "oven",               text: "Oven" },
  { pordalCode: "stove",                        icon: "stove",              text: "Stove" },
  { pordalCode: "bbq-grill",                    icon: "bbq",                text: "BBQ frill" },
  { pordalCode: "patio-balcony",                icon: "patio",              text: "Patio or balcony" },
  { pordalCode: "garden-backyard",              icon: "garden",             text: "Garden or backyard" },
  { pordalCode: "beach-view",                   icon: "beach",              text: "Beach view" },
  { pordalCode: null,                           icon: "beach",              text: "Beach essentials" }
];

allAmenities.forEach(amenity => {
  amenity.lowerCase = amenity.text.toLowerCase();
});

export default allAmenities;
