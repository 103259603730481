import {
  compareObjects,
  getQueryParameters,
  referenceObj,
} from "./helpers/settingsHelper";

export const getColumnCount = () =>{
  let columnCount = 21;
  const gridContainer = document.querySelector('.properties-list-col');
  const width = gridContainer?.getBoundingClientRect().width;
  if (width >= 843.5 && width <= 1045.796875) {
      columnCount = 20;
  } else if (width >= 1046.5 && width <= 1419.59375) {
      columnCount = 21;
  } else if (width >= 1420 && width <= 1779.390625) {
      columnCount = 20;
  } else if (width >= 1780 && width <= 2139.890625) {
      columnCount = 20;
  } else if (width >= 2140) {
      columnCount = 24;
  }
  return columnCount;
}

export const checkAreaMatchesForDescription = (tagParams,cityName) =>{
  const area = settings.matchAreaForDescription.find(area => area.slug === tagParams)?.slug || ''
  return area ? area :cityName
}

const settings = {
	metaDescriptions: {
		dubaiMetaDescription:
			"Find the finest short-term rentals in Dubai. Discover fully furnished and luxurious spaces with flexible terms that are perfect for tourists and expats.",
		jbrMetaDescription:
			"Explore luxury short-term rentals in JBR. Enjoy a beach lifestyle, tourist attractions, fantastic dining, and excellent transport.",
		jltMetaDescription:
			"Experience waterfront living in Jumeirah Lake Towers, Dubai. View modern apartments, all within easy reach of the city's top attractions.",
		difcMetaDescription:
			"Experience the heart of Dubai's business district with Frank Porter’s DIFC short-term rentals. Find luxurious apartments in this vibrant financial hub.",
		villasMetaDescription:
			"Escape to luxurious Dubai with our selection of spacious and opulent villas for rent — fit for families and adventurers alike.",
		"za-abeelMetaDescription":"Ideally located in central Dubai, Za’abeel offers a high-end lifestyle. Discover our Za’abeel apartments for rent to experience the luxury for yourself.",	
		"greater-dubaiMetaDescription":"The Greater Dubai Area affords a luxurious lifestyle for all travellers alike. Explore our selection of short-term apartments for rent in Greater Dubai.",
		"abu-dhabiMetaDescription":
			"Discover top-rated short-term rentals in Abu Dhabi. Choose from fully furnished apartments in prime locations. Flexible booking options, and exceptional amenities.",
		"downtown-dubaiMetaDescription":
			"Discover luxury apartments in Downtown Dubai. Enjoy iconic views, world-class amenities, and city life – ideal for business or leisure stays.",
		"dubai-marinaMetaDescription":
			"Discover luxury short-term rentals in Dubai Marina. Enjoy world-class shopping, dining, and attractions. Perfect for business or leisure.",
		"production-sports-motor-cityMetaDescription":
			"Discover luxury living in Production, Sports & Motor City. Find the perfect apartment or short-term rental for your stay. Enjoy world-class amenities and stunning views.",
		"tecom-greensMetaDescription":
			"Discover our luxurious apartments and short-term rentals ideally situated in Tecom and the Greens. Enjoy easy access to prime shopping centres, dining, and more.",
		"springs-meadowsMetaDescription":
			"Discover the charming family-friendly oasis that is the Springs and Meadows area. Enjoy luxurious living in a tranquil environment.",
		"jvc-jvtMetaDescription":
			"Browse our luxury rentals in Dubai's Jumeirah Village. Enjoy spacious apartments, family-friendly amenities, and easy access to attractions.",
		"business-bayMetaDescription":
			"Experience the best of both worlds in Business Bay. Enjoy upscale living with easy access to Dubai's vibrant business and leisure scene.",
	},
	dubaiFAQs: 3,
	jbrFAQs: 6,
	jltFAQs: 8,
	difcFAQs: 5,
	villasFAQs:5,
	"za-abeelFAQs":5, 
	"greater-dubaiFAQs":5, 
	"abu-dhabiFAQs": 6,
	"dubai-marinaFAQs": 7,
	"downtown-dubaiFAQs": 6,
	"jvc-jvtFAQs": 6,
	"business-bayFAQs": 6,
	"tecom-greensFAQs": 6,
	"springs-meadowsFAQs": 4,
	"production-sports-motor-cityFAQs": 6,
	languages: [
		{ id: 1, name1: "en", name2: "eng" },
		{ id: 2, name1: "ru", name2: "rus" },
		{ id: 3, name1: "fr", name2: "fre" },
		{ id: 4, name1: "ar", name2: "ara" },
	],
	marketingAgreement: "marketing_agreement",
	isDefaulSearch: "isDefaultSearch",
	defaultStarProperties: "defaultStarProperties",
	isAdmin: "isAdmin",
	userLaguague: "user_language",

	frankPorterApiUrl: process.env.REACT_APP_FP_ADMIN_URL,
	guestyApiUrl: process.env.REACT_APP_FP_ADMIN_URL + "/admin/tools/hproxy/v1",
	guestyApiUrlV2: process.env.REACT_APP_FP_ADMIN_URL + "/admin/tools/hproxy/v2",
	frankPorterWebsite: process.env.REACT_APP_FP_WEBSITE_URL,
	frankPorterPayUrl: process.env.REACT_APP_FP_PAY_URL,

	itemsPerPage: () => {
		let columnCount = getColumnCount();
		const queryParams = getQueryParameters();
		const isMatch = compareObjects(queryParams, referenceObj);
		return isMatch ? 100 : columnCount;
	},
	actualItemsPerPage: 21,
	accountId: "5b686412cdbb89003d8323b6",
	apiUsername: "FP-Owner",
	apiPassword: "QXGF4Zr84QoP)fRZa",
	subjects: [
		{ id: 1, translationKey: "subjects:rentShortTerm" },
		{ id: 2, translationKey: "subjects:bookApartment" },
		{ id: 3, translationKey: "subjects:comments" },
		{ id: 4, translationKey: "subjects:others" },
	],
	citiesTags: [
		{
			tag: "DXB",
			urlName: "dubai",
			displayName: "Dubai",
			tagsAvailable: true,
			language: "en",
			visible: window.IS_UAE,
		},
		{
			tag: "AUH",
			urlName: "abu-dhabi",
			displayName: "Abu Dhabi",
			tagsAvailable: true,
			language: "en",
			visible: window.IS_UAE,
		},
		{
			tag: "LED",
			urlName: "stpetersburg",
			displayName: "St. Petersburg",
			tagsAvailable: false,
			language: "ru",
			visible: !window.IS_UAE,
		},
	],
	tagsDubai: [
		{
			tag: "Business Bay",
			translationKey: "tags:businessBay",
			slug: "business-bay",
		},
		{
			tag: "City Walk / Jumeirah",
			translationKey: "tags:cityWalk/Jumeirah",
			slug: "city-walk-jumeirah",
		},
		{
			tag: "DIFC",
			translationKey: "tags:difc",
			slug: "difc",
		},
		{
			tag: "Downtown Dubai",
			translationKey: "tags:downtownDubai",
			slug: "downtown-dubai",
		},
		{
			tag: "Dubai Marina",
			translationKey: "tags:dubaiMarina",
			slug: "dubai-marina",
		},
		{
			tag: "JBR",
			translationKey: "tags:jbr",
			slug: "jbr",
		},
		{
			tag: "JLT",
			translationKey: "tags:jlt",
			slug: "jlt",
		},
		{
			tag: "JVC / JVT",
			translationKey: "tags:jvc/jvt",
			slug: "jvc-jvt",
		},
		{
			tag: "Palm Jumeirah",
			translationKey: "tags:palmJumeirah",
			slug: "palm-jumeirah",
		},
		{
			tag: "Production/Sports/Motor City",
			translationKey: "tags:production/sports/motorCity",
			slug: "production-sports-motor-city",
		},
		{
			tag: "Springs / Meadows",
			translationKey: "tags:springs/meadows",
			slug: "springs-meadows",
		},
		{
			tag: "Tecom / Greens",
			translationKey: "tags:tecom/greens",
			slug: "tecom-greens",
		},
		{
			tag: "Villas",
			translationKey: "tags:villas",
			slug: "villas",
		},
		{
			tag: "Greater Dubai",
			translationKey: "tags:greaterDubai",
			slug: "greater-dubai",
		},
		{
			tag: "Za'abeel",
			translationKey: "tags:zaAbeel",
			slug: "za-abeel",
		},
	],
	tagsAbuDhabi: [
		{
			tag: "Al Saadiyat",
			translationKey: "tags:alSaadiyat",
			slug: "al-saadiyat",
		},
		{
			tag: "Yas Island",
			translationKey: "tags:yasIsland",
			slug: "yas-island",
		},
		{
			tag: "Al Reem Island",
			translationKey: "tags:alReemIsland",
			slug: "al-reem-island",
		},
	],
	tagsStpetersburg: [
		{
			tag: "PTRGRD",
			translationKey: "tags:petrogradskiyIsland",
		},
		{
			tag: "VO",
			translationKey: "tags:vasilievskiyIsland",
		},
		{
			tag: "KOLOMN",
			translationKey: "tags:kolomna",
		},
		{
			tag: "VOSTN",
			translationKey: "tags:ploshadVosstania",
		},
		{
			tag: "LITEINY",
			translationKey: "tags:liteiniy",
		},
		{
			tag: "ADMRL",
			translationKey: "tags:admiralteiskiyDistrict",
		},
		{
			tag: "PRIM",
			translationKey: "tags:primorskiyDistrict",
		},
		{
			tag: "MOSK",
			translationKey: "tags:airport",
		},
		{
			tag: "5UGL",
			translationKey: "tags:piyatUglov",
		},
	],
	bedrooms: [
		{ name: "Studio", translationKey: "common:studio", values: [0] },
		{ name: "1BR", translationKey: "common:1br", values: [1] },
		{ name: "2BR", translationKey: "common:2br", values: [2] },
		{ name: "3BR", translationKey: "common:3br", values: [3] },
		{ name: "4BR", translationKey: "common:4br", values: [4] },
		{ name: "5BR", translationKey: "common:5br", values: [5, 6, 7] },
	],
	sortMethods: [
		{
			translationKey: "search:default",
			key: "_id",
			reservationKey: "_id",
		},
		{
			translationKey: "search:price",
			key: "prices.basePrice",
			reservationKey: "reservation.pricePerNight",
		},
	],
	stripeAccount: "acct_1Be30yJjR8Qiquyz",
	stripeApiKey: "pk_live_P0FSIEtbwU1GSvgvEM3DYuUZ",
	menuItems: [
		{
			url: "",
			translationKey: "home",
			hiddenInLanguages: [],
			visibleTopMenu: true,
			visibleBottomMenu: false,
		},
		{
			url: "contact",
			translationKey: "contact",
			hiddenInLanguages: [],
			visibleTopMenu: true,
			visibleBottomMenu: false,
		},
		{
			url: "services",
			translationKey: "services",
			hiddenInLanguages: [],
			visibleTopMenu: true,
			visibleBottomMenu: false,
		},
		{
			url: "privacy-policy",
			translationKey: "privacyPolicy",
			hiddenInLanguages: [],
			visibleTopMenu: true,
			visibleBottomMenu: true,
		},
	],
	matchAreaForDescription: [
		{
			tag: "Dubai Marina",
			translationKey: "tags:dubaiMarina",
			slug: "dubai-marina",
		},
		{
			tag: "Downtown Dubai",
			translationKey: "tags:downtownDubai",
			slug: "downtown-dubai",
		},
    	{
			tag: "JBR",
			translationKey: "tags:jbr",
			slug: "jbr",
		},
    	{
			tag: "JLT",
			translationKey: "tags:jlt",
			slug: "jlt",
		},
		{
			tag: "JVC / JVT",
			translationKey: "tags:jvc/jvt",
			slug: "jvc-jvt",
		},
		{
			tag: "Business Bay",
			translationKey: "tags:businessBay",
			slug: "business-bay",
		},
		{
			tag: "Production/Sports/Motor City",
			translationKey: "tags:production/sports/motorCity",
			slug: "production-sports-motor-city",
		},
		{
			tag: "Springs / Meadows",
			translationKey: "tags:springs/meadows",
			slug: "springs-meadows",
		},
		{
			tag: "Tecom / Greens",
			translationKey: "tags:tecom/greens",
			slug: "tecom-greens",
		},
		{
			tag: "DIFC",
			translationKey: "tags:difc",
			slug: "difc",
		},
		{
			tag: "Villas",
			translationKey: "tags:villas",
			slug: "villas",
		},
		{
			tag: "Za'abeel",
			translationKey: "tags:zaAbeel",
			slug: "za-abeel",
		},
		{
			tag: "Greater Dubai",
			translationKey: "tags:greaterDubai",
			slug: "greater-dubai",
		},
	],
};

export default settings;
